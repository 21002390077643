import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as models from '../../helpers/models';
import * as cfg from '../../helpers/config';
import {AuthenticationService} from '../../helpers/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaService} from '../../helpers/media.service';
import {ProfileLayoutComponent} from '../profile-layout.component';
import * as utils from '../../helpers/utils';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl = '/';
  loading = false;
  isFirstNavigation = false;
  loginInfo: models.ILoginInfo = {username: '', password: '', socialLogin: ''};
  hasError = false;
  hasUserError = false;
  isForgotPassword = false;

  constructor(private authenticationService: AuthenticationService,
              private mediaService: MediaService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent) {
    this.authenticationService.onSignInSuccess
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response, status: this.authenticationService.authenticationStatus, url: this.returnUrl});
        if (this.returnUrl) {
          this.parent.winRef.reloadLocation(this.returnUrl.replace('/', ''));
          // this.router.navigateByUrl(this.returnUrl).then(v => {
          //   const urlTree = this.router.parseUrl(this.returnUrl);
          //   urlTree.queryParams = {};
          //   this.authenticationService.lastRoute = urlTree.toString();
          // });
        } else {
          this.authenticationService.authenticate();
        }
      });
    this.authenticationService.onAuthenticationEnd
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response}, 'authentication end on login');
        this.navigateToDefault();
      });
    this.authenticationService.onAuthenticationStart
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log('authentication start on login');
        this.loading = true;
      });
  }

  get isShowLicenseError(): boolean {
    return Boolean(this.authenticationService.authenticationStatus.err &&
      this.authenticationService.authenticationStatus.errCode === 'user_inactive');
  }

  get failedAttempts(): number {
    return this.authenticationService.authenticationStatus.failedAttempts || 0;
  }

  get isUserEmailNotFound(): boolean {
    return this.authenticationService.authenticationStatus.err === 'user email is not found';
  }

  get isLoginBlocked(): boolean {
    return this.failedAttempts >= cfg.MAX_AMOUNT_OF_LOGIN_ATTEMPTS;
  }

  get maxAttempts(): number {
    return cfg.MAX_AMOUNT_OF_LOGIN_ATTEMPTS;
  }

  get isValidEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return !!this.loginInfo.username && re.test(this.loginInfo.username);
  }

  sendEmail(): void {
    this.hasError = !this.isValidEmail;
    if (!this.hasError) {
      this.hasUserError = false;
      this.authenticationService.forgotPassword(this.loginInfo.username).subscribe(
        (response) => {
          console.log(response);
          if (response && response.err) {
            this.hasUserError = true;
          } else {
            this.isForgotPassword = false;
            this.messageService.add({
              severity: 'success',
              detail: 'A reset link has been sent to your email!',
              closable: false,
              key: 'reset-link'});
          }
        }
      );
    }
  }

  signIn(): void {
    this.hasError = !this.isValidEmail;
    if (!this.hasError && !this.isLoginBlocked) {
      this.loginInfo.socialLogin = '';
      this.loading = true;
      this.authenticationService.signIn(this.loginInfo);
      this.authenticationService.isBlocked(this.loginInfo.username || '');
      this.parent.winRef.reportGtagEventNew('login', {method: 'Email'});
    }
  }

  createAccount(): void {
    // this.parent.winRef.reloadLocation('join');
    this.router.navigate(['/join']);
  }

  ngOnInit(): void {
    this.loading = this.mediaService.isAuthenticationInProcess;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
    this.isFirstNavigation = this.route.snapshot.data.firstNavigation || false;
    this.parent?.authService.authState.subscribe((user) => {
      if (user) {
        this.loginInfo.socialLogin = user.provider;
        this.loginInfo.username = user.email;
        this.loading = true;
        this.authenticationService.signIn(this.loginInfo);
        this.parent.winRef.reportGtagEventNew('login', {method: utils.capitalize(this.loginInfo.socialLogin)});
      }
    });
  }

  private navigateToDefault(): void {
    if (!this.returnUrl) {
      const modules = this.mediaService.modules;
      // let module: any = this.authenticationService.lastRoute;
      let module = 'my-cloud';
      if (this.mediaService.isFeeditorPlanSelected) {
        module = 'feeditor';
      }
      if (this.mediaService.isFeeditorPlusPlanSelected) {
        module = 'feeditor-plus';
      }
      if (!this.mediaService.isAdminRole && !!modules.mediablast) {
        module = 'media-blast/cockpit';
      }
      if (this.mediaService?.currentUser && !this.mediaService?.currentUser?.upgradedLogin) {
        module = 'upgrade-login';
      }
      // console.log('navigateToDefault  module', module);
      this.router.navigateByUrl(`/${module}`).then(v => {
        this.loading = false;
      });
    }
  }

  isFieldInvalid(): boolean {
    return this.hasError || this.hasUserError || this.isShowLicenseError ||
      !this.isShowLicenseError && this.failedAttempts > 0 && this.failedAttempts < (this.maxAttempts - 1) ||
      !this.isShowLicenseError && this.failedAttempts === (this.maxAttempts - 1) ||
      !this.isShowLicenseError && this.isLoginBlocked;
  }

}
